<template>
	<section class="talentBox">
		<div class="assessBox">
			<div class="app-container">
				<div class="titlebox" v-if="!iscomponent">
					<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
					<div class="title">{{ Viewform.userName || $route.query.name }}-人才评估</div>
				</div>
				<el-form ref="assessForm" :model="assessForm" :rules="rules">
					<div class="viewbox">
						<!-- <div class="level_box">
              <div class="level_box_levelbox">
                <span class="level_box_now">现职级：{{Viewform.managerStation||"暂无评"}}级</span>
              </div>
            </div> -->
						<div class="title" style="padding: 14px 0; font-weight: 600" v-if="isPosition || isManage">一、现岗胜任度评估</div>
						<template v-if="isPosition">
							<div>1、责任结果评估（适用所有评估对象）</div>
							<el-form-item prop="rankName" label="a、评估所在层级">
								<br />
								<div>
									<span class="viewbox_position">
										<el-radio-group
											v-model="assessForm.rankName"
											style="margin-left: 6px"
											:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
											@change="clearValidate('assessForm')"
										>
											<el-radio label="0">战略决策层</el-radio>
											<el-radio label="1">战略执行层</el-radio>
											<el-radio label="2">部门运营层</el-radio>
											<el-radio label="3">业务执行层</el-radio>
											<el-radio label="4">非管理层及其他</el-radio>
										</el-radio-group>
									</span>
								</div>
							</el-form-item>
							<el-form-item label="关键区分事实描述" prop="rankNote" class="fwn">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										maxlength="500"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.rankNote"
									></el-input>
								</div>
							</el-form-item>
						</template>
						<template v-if="isManage">
							<div>{{ isManage ? 1 : 2 }}、管理胜任力评估（部门运行层及以上层级需要评估且需要填写评估依据）</div>
							<el-form-item label="a、战略能力" prop="strategy" :required="isRules ? isStrategy : false">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.strategy"
										class="radioArr"
										@change="setRankLevelByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">0级</el-radio>
										<el-radio label="1">1级</el-radio>
										<el-radio label="2">2级</el-radio>
										<el-radio label="3">3级</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="strategyNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										maxlength="500"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.strategyNote"
									>
									</el-input>
								</div>
							</el-form-item>
							<el-form-item label="b、变革能力" prop="changeExpert" :required="isRules ? isStrategy : false">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.changeExpert"
										class="radioArr"
										@change="setRankLevelByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">0级</el-radio>
										<el-radio label="1">1级</el-radio>
										<el-radio label="2">2级</el-radio>
										<el-radio label="3">3级</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="changeNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										maxlength="500"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.changeNote"
									>
									</el-input>
								</div>
							</el-form-item>
							<el-form-item label="c、高效体系构建能力" prop="flowExpert" :required="isRules ? isStrategy : false">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.flowExpert"
										class="radioArr"
										@change="setRankLevelByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">0级</el-radio>
										<el-radio label="1">1级</el-radio>
										<el-radio label="2">2级</el-radio>
										<el-radio label="3">3级</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="flowNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										maxlength="500"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.flowNote"
									></el-input>
								</div>
							</el-form-item>
							<el-form-item label="d、人才队伍建设能力" prop="employeeAdvocate" :required="isRules ? isStrategy : false">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.employeeAdvocate"
										class="radioArr"
										@change="setRankLevelByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">0级</el-radio>
										<el-radio label="1">1级</el-radio>
										<el-radio label="2">2级</el-radio>
										<el-radio label="3">3级</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="advocateNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										maxlength="500"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.advocateNote"
									>
									</el-input>
								</div>
							</el-form-item>
						</template>

						<!-- <el-form-item label=""></el-form-item> -->
						<template v-if="isManage">
							<div>{{ isManage ? 2 : 3 }}.现岗胜任度评估结果（非管理层无需评价）</div>
							<el-form-item label="a、职位胜任力要求的符合度" prop="rankLevel" :required="isRules ? isRankLevel : false">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.rankLevel"
										class="radioArr"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="1">完全胜任</el-radio>
										<el-radio label="2">基本胜任</el-radio>
										<el-radio label="3">暂不胜任</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item
								label="现岗胜任度综合评估说明"
								prop="competencyNote"
								:required="isRules ? isCompetencyNote : false"
								class="fwn"
							>
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										maxlength="500"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.competencyNote"
									>
									</el-input>
								</div>
							</el-form-item>
						</template>
					</div>
					<div class="qualityevaluation">
						<template v-if="isQuality">
							<div class="title">二、五项素质评估（适用所有评估对象，且经理及以上层级需要分项描述依据）</div>
							<el-form-item label="1、主动性" prop="initiative">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.initiative"
										class="radioArr ia"
										@change="setTalentTypeByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">
											<span style="color: #000; padding: 0 5px">0级</span>
											<span style="color: #999999"
												>(
												<span style="color: #000; padding: 0 5px">不主动</span
												>不会自觉地完成工作任务，需要他人的督促。不能提前计划或思考问题，直到问题发生后才能意识到事情的严重性。)</span
											>
										</el-radio>
										<el-radio label="1">
											<span style="color: #000; padding: 0 5px">1级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">主动行动</span
												>主动性一级的人能主动行动，自觉投入更多的努力去工作，不需要别人督促，只要分配给他的工作，他就会自觉地投入时间去做。)
											</span>
										</el-radio>
										<el-radio label="2">
											<span style="color: #000; padding: 0 5px">2级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">主动思考，快速行动</span
												>及时发现某种机会和问题并快速做出反应。主动性二级的人不光能快速自觉地工作，而且会主动进行思考，预判某一种情况，然后采取相应的行动。)
											</span>
										</el-radio>
										<el-radio label="3">
											<span style="color: #000; padding: 0 5px">3级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">未雨绸缪 提前行动</span
												>主动性三级的人不会等着问题发生，而是会未雨绸缪，提前行动，规避问题甚至创造出机会来。)
											</span>
										</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="initiativeNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										maxlength="500"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.initiativeNote"
									>
									</el-input>
								</div>
							</el-form-item>
							<el-form-item label="2、概念思维" prop="conceptThinking">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.conceptThinking"
										class="radioArr ia"
										@change="setTalentTypeByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">
											<span style="color: #000; padding: 0 5px">0级</span>
											<span style="color: #999999"
												>(
												<span style="color: #000; padding: 0 5px">想不清看不明</span
												>不能准确而周密地考虑事物发生的原因，或者不能根据已有的经验或知识对当前所面临的问题做出正确的判断。)</span
											>
										</el-radio>
										<el-radio label="1">
											<span style="color: #000; padding: 0 5px">1级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">简单类比</span
												>能根据以往的成功经验，通过有限的信息对事物进行全面的认识或判断，能够辨别当前情况同过去的经历之间的相同之处和不同之处，在新的条件下成功复制以往经验。)
											</span>
										</el-radio>
										<el-radio label="2">
											<span style="color: #000; padding: 0 5px">2级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">触类旁通</span
												>通过对事物发展规律的总结与洞察，提炼出一套底层的方法逻辑，能够透过复杂的信息对眼前的问题进行整体分析和处理，抓住关键问题。对于陌生的知识或领域，能够快速穿透，跨越认知壁垒。)
											</span>
										</el-radio>
										<el-radio label="3">
											<span style="color: #000; padding: 0 5px">3级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">深入浅出</span
												>能够洞察复杂事物的底层本质，并将复杂的情况或观点用简单、通俗的方式清晰有效地表达出来。能够用更加简洁的方法阐述繁复的知识和现象。)
											</span>
										</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="conceptNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										maxlength="500"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.conceptNote"
									>
									</el-input>
								</div>
							</el-form-item>
							<el-form-item label="3、影响力" prop="influence">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.influence"
										class="radioArr ia"
										@change="setTalentTypeByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">
											<span style="color: #000; padding: 0 5px">0级</span>
											<span style="color: #999999"
												>(
												<span style="color: #000; padding: 0 5px">不能影响</span
												>不能清楚地表达自己要讲的内容或不能说服别人听从自己的观点。)</span
											>
										</el-radio>
										<el-radio label="1">
											<span style="color: #000; padding: 0 5px">1级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">直接说服</span
												>通过向别人讲述理由、证据、事实、数据等方式，直接说服别人。或者给别人留下好的印象，以便获得别人的认可。)
											</span>
										</el-radio>
										<el-radio label="2">
											<span style="color: #000; padding: 0 5px">2级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">设身处地</span
												>能换位思考，站在他人的角度，用他人的话去解决他的问题，或同时采取多种方式影响他人。)
											</span>
										</el-radio>
										<el-radio label="3">
											<span style="color: #000; padding: 0 5px">3级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">综合策略</span
												>运用复杂的策略影响他人或通过微妙的幕后操作使别人接受自己的观点。)
											</span>
										</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="influenceNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										maxlength="500"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										v-model="assessForm.influenceNote"
									></el-input>
								</div>
							</el-form-item>
							<el-form-item label="4、成就导向" prop="achievementGuide">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.achievementGuide"
										class="radioArr ia"
										@change="setTalentTypeByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">
											<span style="color: #000; padding: 0 5px">0级</span>
											<span style="color: #999999"
												>(
												<span style="color: #000; padding: 0 5px">安于现状</span
												>安于现状，不追求个人技术或专业修养方面的进步。)</span
											>
										</el-radio>
										<el-radio label="1">
											<span style="color: #000; padding: 0 5px">1级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">追求更好</span>努力将工作做得更好，或达到某个优秀的标准。)
											</span>
										</el-radio>
										<el-radio label="2">
											<span style="color: #000; padding: 0 5px">2级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">挑战目标</span
												>为自己设立富有挑战性的目标，并为达到这些目标而付诸行动。)
											</span>
										</el-radio>
										<el-radio label="3">
											<span style="color: #000; padding: 0 5px">3级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">敢冒经过评估的风险</span
												>在仔细权衡代价和利益、利与弊的基础上作出某种决策，为了获得更大的成功，敢于冒险。)
											</span>
										</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="guideNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										maxlength="500"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.guideNote"
									></el-input>
								</div>
							</el-form-item>
							<el-form-item label="5、坚韧性" prop="hardiness">
								<br />
								<div>
									<el-radio-group
										v-model="assessForm.hardiness"
										class="radioArr ia"
										@change="setTalentTypeByRadioChange"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									>
										<el-radio label="0">
											<span style="color: #000; padding: 0 5px">0级</span>
											<span style="color: #999999"
												>(
												<span style="color: #000; padding: 0 5px">扛不住</span
												>不能控制自己的情绪，经受不了批评、挫折和压力，稍微遇到点压力就选择放弃。)</span
											>
										</el-radio>
										<el-radio label="1">
											<span style="color: #000; padding: 0 5px">1级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">压不垮</span
												>在工作中能够保持良好的体能和稳定的情绪状态；面对挫折时能够克制自己的消极情绪（愤怒、焦急、失望等），努力坚持，不言放弃。)
											</span>
										</el-radio>
										<el-radio label="2">
											<span style="color: #000; padding: 0 5px">2级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">干得成</span
												>不仅能在艰苦的环境中顶住压力，重要的是一定能把事做成，克服困难后会带来好的结果。)
											</span>
										</el-radio>
										<el-radio label="3">
											<span style="color: #000; padding: 0 5px">3级</span>
											<span style="color: #999999">
												(
												<span style="color: #000; padding: 0 5px">解除掉</span
												>能通过建设性的方式消除他人的敌意或保证自己情绪的稳定，不受制于压力的影响，能把压力解除，顺利完成任务。)
											</span>
										</el-radio>
									</el-radio-group>
								</div>
							</el-form-item>
							<el-form-item label="评估依据" prop="hardinessNote" class="fwn" :required="isRules ? isStrategyNote : false">
								<div>
									<el-input
										style="margin-top: -12px"
										type="textarea"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										maxlength="500"
										:autosize="{ minRows: 3, maxRows: 4 }"
										placeholder="1-500字"
										v-model="assessForm.hardinessNote"
									>
									</el-input>
								</div>
							</el-form-item>
							<el-form-item label="综合素质评价" prop="synthesizeAppraise">
								<el-input
									class="big-textarea"
									type="textarea"
									maxlength="500"
									:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
									:autosize="{ minRows: 3, maxRows: 8 }"
									placeholder="1-500字"
									v-model="assessForm.synthesizeAppraise"
								>
								</el-input>
							</el-form-item>
							<div class="Talentstype">
								<el-form-item label="人才类型" prop="talentType">
									<el-radio-group
										v-model="assessForm.talentType"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										class="radioArr"
									>
										<el-radio label="0">企业家型人才</el-radio>
										<el-radio label="1">开创型人才</el-radio>
										<el-radio label="2">守成型人才</el-radio>
										<el-radio label="3">执行型人才</el-radio>
										<el-radio label="4">非人才</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
							<div class="Postproposal">
								<el-form-item label="任职建议" prop="appointment">
									<el-radio-group
										v-model="assessForm.appointment"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										class="radioArr"
									>
										<el-radio label="0">保存现岗</el-radio>
										<el-radio label="1">提拔使用</el-radio>
										<el-radio label="2">调岗使用</el-radio>
										<el-radio label="3">降级使用</el-radio>
										<el-radio label="4">其他</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
							<div class="Postproposal">
								<el-form-item label="任职建议说明" prop="appointmentNote">
									<strong style="font: bold 14px/25px 'Microsoft YaHei'"></strong>
									<el-input
										class="Postproposal_input"
										type="textarea"
										maxlength="500"
										:disabled="$route.name == 'interviewAppraisalInfo' || $route.query.talentId"
										:autosize="{ minRows: 3, maxRows: 4 }"
										v-model="assessForm.appointmentNote"
										placeholder="1-500字"
									>
									</el-input>
								</el-form-item>
							</div>
						</template>
						<div class="buttonBox" v-if="$route.name != 'interviewAppraisalInfo' && !$route.query.talentId">
							<el-button type="plain" size="medium" class="buttonBox_cancel" @click="handleCancle">取消</el-button>
							<el-button type="success" size="medium" class="buttonBox_save" @click="save('assessForm')">保存</el-button>
							<el-button type="primary" size="medium" @click="submit('assessForm')">提交</el-button>
						</div>
					</div>
				</el-form>
			</div>
		</div>
	</section>
</template>
<script>
import * as api from '@/api/talentassessment';
import { talentSelectUserAssessReport } from '@/api/recruitment_allocation';
export default {
	props: {
		iscomponent: {
			type: Boolean,
			required: false
		}
	},
	data() {
		const rankName = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验this.assessForm.strategyNote === ""&&
			if (value === '') {
				callback(new Error('请选择职位'));
			} else {
				callback();
			}
		};
		// 验证活动名称的函数

		const valstrategyNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验this.assessForm.strategyNote === ""&&
			if (value === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valchangeNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.changeNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valrankLevel = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.rankLevel === '' && this.isRankLevel) {
				callback(new Error('请选择符合度'));
			} else {
				callback();
			}
		};

		const valstrategy = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.strategy === '' && this.isStrategy) {
				callback(new Error('请选择对应程度'));
			} else {
				callback();
			}
		};

		const valchangeExpert = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.changeExpert === '' && this.isStrategy) {
				callback(new Error('请选择对应程度'));
			} else {
				callback();
			}
		};

		const valflowExpert = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.flowExpert === '' && this.isStrategy) {
				callback(new Error('请选择对应程度'));
			} else {
				callback();
			}
		};

		const valflowNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.flowNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valemployeeAdvocate = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.employeeAdvocate === '' && this.isStrategy) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valadvocateNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.advocateNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valcompetencyNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.competencyNote === '' && this.isCompetencyNote) {
				callback(new Error('请填写现岗胜任度综合评估说明'));
			} else {
				callback();
			}
		};

		const valinitiativeNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.initiativeNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valconceptNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.conceptNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const influence = (rule, value, callback) => {
			if (!value) {
				callback(new Error('请选择对应级别'));
			} else {
				callback();
			}
		};

		const valinfluenceNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.influenceNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valguideNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.guideNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const valhardinessNote = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验
			if (this.assessForm.hardinessNote === '' && this.isStrategyNote) {
				callback(new Error('请填写对应评估依据'));
			} else {
				callback();
			}
		};

		const hardiness = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验this.assessForm.strategyNote === ""&&
			if (value === '') {
				callback(new Error('请选择对应级别'));
			} else {
				callback();
			}
		};

		const synthesizeAppraise = (rule, value, callback) => {
			// 当活动名称为空值且为必填时，抛出错误，反之通过校验this.assessForm.strategyNote === ""&&
			if (value === '') {
				callback(new Error('请填写综合素质评价'));
			} else {
				callback();
			}
		};

		const talentType = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择人才类型'));
			} else {
				callback();
			}
		};

		const appointment = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择任职建议'));
			} else {
				callback();
			}
		};

		const appointmentNote = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请填写任职建议说明'));
			} else {
				callback();
			}
		};

		return {
			isRules: false,
			rules: {
				rankName: [{ validator: rankName, trigger: 'change' }],
				rankLevel: [{ validator: valrankLevel }],
				rankNote: [{ required: this.$route.name != 'interviewAppraisalInfo', message: '请填写关键区分事实描述', trigger: 'blur' }],
				strategy: [{ validator: valstrategy }],
				strategyNote: [{ validator: valstrategyNote }],
				changeExpert: [{ validator: valchangeExpert }],
				changeNote: [{ validator: valchangeNote }],
				flowExpert: [{ validator: valflowExpert }],
				flowNote: [{ validator: valflowNote }],
				employeeAdvocate: [{ validator: valemployeeAdvocate }],
				advocateNote: [{ validator: valadvocateNote }],
				competencyNote: [{ validator: valcompetencyNote }],
				initiative: [{ required: this.$route.name != 'interviewAppraisalInfo', message: '请选择对应级别', trigger: 'change' }],
				initiativeNote: [{ validator: valinitiativeNote }],
				conceptThinking: [{ required: this.$route.name != 'interviewAppraisalInfo', message: '请选择对应级别', trigger: 'change' }],
				conceptNote: [{ validator: valconceptNote }],
				influence: [{ required: this.$route.name != 'interviewAppraisalInfo', validator: influence, trigger: 'change' }],
				influenceNote: [{ validator: valinfluenceNote }],
				achievementGuide: [{ required: this.$route.name != 'interviewAppraisalInfo', message: '请选择对应级别', trigger: 'change' }],
				guideNote: [{ validator: valguideNote }],
				hardiness: [{ required: this.$route.name != 'interviewAppraisalInfo', validator: hardiness, trigger: 'change' }],
				hardinessNote: [{ required: this.$route.name != 'interviewAppraisalInfo', validator: valhardinessNote }],
				synthesizeAppraise: [{ required: this.$route.name != 'interviewAppraisalInfo', validator: synthesizeAppraise, trigger: 'blur' }],
				talentType: [{ validator: talentType, trigger: 'change' }],
				appointment: [{ validator: appointment, trigger: 'change' }],
				appointmentNote: [{ validator: appointmentNote, trigger: 'blur' }]
			},
			assessForm: {
				id: '',
				rankName: '',
				rankLevel: '',
				rankNote: '',
				strategy: '',
				strategyNote: '',
				changeExpert: '',
				changeNote: '',
				flowExpert: '',
				flowNote: '',
				employeeAdvocate: '',
				advocateNote: '',
				initiative: '',
				initiativeNote: '',
				conceptThinking: '',
				conceptNote: '',
				influence: '',
				influenceNote: '',
				achievementGuide: '',
				guideNote: '',
				hardiness: '',
				hardinessNote: '',
				synthesizeAppraise: '',
				competencyNote: '',
				appointment: '',
				appointmentNote: '',
				talentType: ''
			},
			Viewform: {
				id: '',
				userName: '',
				managerStation: ''
			},
			currentItem: {}
		};
	},
	watch: {
		'$parent.talentForm': {
			handler(val) {
				if (val) {
					// eslint-disable-next-line guard-for-in
					for (const key in this.$parent.talentForm) {
						for (const key1 in this.assessForm) {
							if (key == key1 && this.$parent.talentForm[key]) {
								this.assessForm[key1] = this.$parent.talentForm[key];
							}
						}
					}
				}
			},
			deep: true
		}
		// rankName(val){
		//   if(val!=0){
		//     this.competencyNote="";
		//   }
		// }
	},
	computed: {
		isStrategy() {
			return Number(this.assessForm.rankName) < 3;
		},
		isStrategyNote() {
			return Number(this.assessForm.rankName) === 0 || Number(this.assessForm.rankName) === 1;
		},
		isRankLevel() {
			return this.assessForm.rankName != '4';
		},
		isCompetencyNote() {
			return this.assessForm.rankName == '0';
		},
		tabKey() {
			return this.$route.query.tabKey;
		},
		// 素质
		isQuality() {
			return this.tabKey === '2';
		},
		// 站位
		isPosition() {
			return this.tabKey === '3';
		},
		// 管理能力
		isManage() {
			return this.tabKey === '4';
		}
	},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`) || '{ }');

		const { activeName } = data;

		this.activeName = activeName;
		console.log(activeName, 'activeName');
		if (this.$route.query) {
			this.Viewform.id = this.$route.query.id;
			// eslint-disable-next-line no-unused-expressions
			this.Viewform.userName = this.$route.query.userName;
			this.Viewform.managerStation = this.$route.query.managerStation;
		}
		// if (this.$route.query.talentId && this.$route.name == 'TalentAssessment') {
		// 	this._getDetail();
		// } else if (this.$route.query.status == 2) {
		// 	this.getDetail();
		// }
		this.getDetail();
	},
	methods: {
		// 为“talentType-人才类型”自动赋值
		setTalentTypeByRadioChange() {
			const assessObject = this.assessForm;
			// 主动性等级

			const { initiative } = assessObject;
			// 概念思维等级

			const { conceptThinking } = assessObject;
			// 影响力等级

			const { influence } = assessObject;
			// 成就导向等级

			const { achievementGuide } = assessObject;
			// 坚韧性等级

			const { hardiness } = assessObject;

			let talentTypeStr = '4';

			if (initiative >= 1 && conceptThinking >= 1 && influence >= 1 && achievementGuide >= 1 && hardiness >= 1) {
				talentTypeStr = '3';
			}
			if (initiative >= 1 && conceptThinking >= 1 && influence >= 1 && achievementGuide >= 1 && hardiness >= 2) {
				talentTypeStr = '2';
			}
			if (initiative >= 2 && conceptThinking >= 2 && influence >= 2 && achievementGuide >= 2 && hardiness >= 2) {
				talentTypeStr = '1';
			}
			if (initiative >= 3 && conceptThinking >= 2 && influence >= 2 && achievementGuide >= 3 && hardiness >= 2) {
				talentTypeStr = '0';
			}
			this.assessForm.talentType = talentTypeStr;
		},
		// 为“rankLevel-现岗胜任度评估结果”自动赋值
		setRankLevelByRadioChange() {
			const assessObject = this.assessForm;
			// 当前所在层级

			let currentRankName = this.Viewform.managerStation;
			// 评估所在层级

			const { rankName } = assessObject;
			// 战略能力等级

			const { strategy } = assessObject;
			// 变革能力等级

			const { changeExpert } = assessObject;
			// 高效体系构建能力等级

			const { flowExpert } = assessObject;
			// 人才队伍建设能力等级

			const { employeeAdvocate } = assessObject;

			let result = '3';

			switch (currentRankName) {
				case '战略决策层':
					currentRankName = '0';
					break;
				case '战略执行层':
					currentRankName = '1';
					break;
				case '部门运营层':
					currentRankName = '2';
					break;
				case '业务执行层':
					currentRankName = '3';
					break;
				case '非管理层及其他':
					currentRankName = '4';
					break;
				default:
					break;
			}
			console.log('当前所在层级:', currentRankName, ', 评估所在层级:', rankName);
			if (currentRankName != rankName) {
				result = '3';
			} else {
				switch (rankName) {
					case '0':
						if (strategy >= 3 && changeExpert >= 2 && flowExpert >= 2 && employeeAdvocate >= 2) {
							result = '2';
						}
						if (strategy >= 3 && changeExpert >= 3 && flowExpert >= 3 && employeeAdvocate >= 3) {
							result = '1';
						}
						break;
					case '1':
						if (strategy >= 2 && changeExpert >= 1 && flowExpert >= 1 && employeeAdvocate >= 2) {
							result = '2';
						}
						if (strategy >= 2 && changeExpert >= 2 && flowExpert >= 2 && employeeAdvocate >= 2) {
							result = '1';
						}
						break;
					case '2':
						if (strategy >= 1 && changeExpert >= 1 && flowExpert >= 1 && employeeAdvocate >= 1) {
							result = '2';
						}
						if (strategy >= 1 && changeExpert >= 1 && flowExpert >= 2 && employeeAdvocate >= 2) {
							result = '1';
						}
						break;
					case '3':
						if (strategy >= 0 && changeExpert >= 0 && flowExpert >= 1 && employeeAdvocate >= 1) {
							result = '2';
						}
						if (strategy >= 1 && changeExpert >= 1 && flowExpert >= 1 && employeeAdvocate >= 1) {
							result = '1';
						}
						break;
					default:
						console.log(
							'为“现岗胜任度评估结果”自动赋值异常！当前所在层级：',
							currentRankName,
							'，评估所在层级：',
							rankName,
							'，战略能力等级：',
							strategy,
							'，变革能力等级：',
							changeExpert,
							'，高效体系构建能力等级：',
							flowExpert,
							'，人才队伍建设能力等级：',
							employeeAdvocate
						);
						break;
				}
			}
			this.assessForm.rankLevel = result;
		},
		_getDetail() {
			talentSelectUserAssessReport({
				id: this.$route.query.id || this.$route.query.talentId,
				type: '0'
			}).then((res) => {
				// eslint-disable-next-line guard-for-in
				for (const key in res) {
					this.assessForm[key] = res[key] || '';
				}
			});
		},
		clearValidate(formName) {
			this.setRankLevelByRadioChange();
			this.$refs[formName].clearValidate();
		},

		// 取消
		handleCancle() {
			this.$router.go(-1);
		},
		getDetail() {
			this.$axios
				.post('/v1/talent/selectUserAssessReport', {
					request: {
						id: this.$route.query.id || this.$route.query.talentId,
						planId: this.$route.query.planId,
						type: this.$route.query.talentId ? '0' : '2'
					}
				})
				.then((res) => {
					// eslint-disable-next-line guard-for-in
					for (const key in res) {
						// for (const key1 in this.assessForm) {
						//   if (key == key1) {
						this.assessForm[key] = res[key] || '';
						//   }
						// }
					}
				});
		},
		save() {
			this.isRules = false;
			this.$refs.assessForm.clearValidate();
			//   this.$refs.assessForm.resetFields();
			//   this.$refs[formName].validate((valid) => {
			//   if (valid) {
			const params = {
				request: {
					rankName: '',
					rankLevel: '',
					rankNote: '',
					strategy: '',
					strategyNote: '',
					changeExpert: '',
					changeNote: '',
					flowExpert: '',
					flowNote: '',
					employeeAdvocate: '',
					advocateNote: '',
					initiative: '',
					initiativeNote: '',
					conceptThinking: '',
					conceptNote: '',
					influence: '',
					influenceNote: '',
					achievementGuide: '',
					guideNote: '',
					hardiness: '',
					hardinessNote: '',
					synthesizeAppraise: '',
					competencyNote: '',
					appointment: '',
					appointmentNote: '',
					talentType: ''
				}
			};

			// eslint-disable-next-line guard-for-in
			for (const key in params.request) {
				for (const key1 in this.assessForm) {
					if (key == key1) {
						params.request[key] = this.assessForm[key1];
					}
				}
			}
			params.request.userId = this.Viewform.id;
			if (this.$route.query.planId) {
				params.request.planId = this.$route.query.planId;
			}
			if (!this.iscomponent) {
				this.$axios.post('/v1/talent/draftUserAccessReport', params).then((res) => {
					if (res && res.code == 0) {
						this.$message.success('保存成功');
						// this.$router.push({ name: 'TalentAssessmentCenter' });
						this.handleCancle();
					} else {
						this.$message.error(res.message);
					}
				});
			} else {
				this.$parent.submit(params.request, '2');
			}
			//   }
			//   })
		},
		submit(formName) {
			this.isRules = true;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const params = {
						request: {
							rankName: '',
							rankLevel: '',
							rankNote: '',
							strategy: '',
							strategyNote: '',
							changeExpert: '',
							changeNote: '',
							flowExpert: '',
							flowNote: '',
							employeeAdvocate: '',
							advocateNote: '',
							initiative: '',
							initiativeNote: '',
							conceptThinking: '',
							conceptNote: '',
							influence: '',
							influenceNote: '',
							achievementGuide: '',
							guideNote: '',
							hardiness: '',
							hardinessNote: '',
							synthesizeAppraise: '',
							competencyNote: '',
							appointment: '',
							appointmentNote: '',
							talentType: ''
						}
					};

					// eslint-disable-next-line guard-for-in
					for (const key in params.request) {
						for (const key1 in this.assessForm) {
							if (key == key1) {
								params.request[key] = this.assessForm[key1];
							}
						}
					}
					params.request.userId = this.Viewform.id;
					if (this.$route.query.planId) {
						params.request.planId = this.$route.query.planId;
					}
					if (!this.iscomponent) {
						api.submittalent(params).then((res) => {
							if (res && res.code == 0) {
								this.$message.success('人才评估提交成功');
								// this.$router.push({ name: 'TalentAssessmentCenter' });
								this.handleCancle();
							}
						});
					} else {
						this.$parent.submit(params.request, '1');
					}
				}
			});
		}
		// handleGoAudit(){

		// },
		// 导出功能
		// handleExport() {

		// }
	}
};
</script>

<style lang="scss">
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}
</style>

<style lang="scss">
.level_box {
	display: flex;

	// background-color: pink;
	.level_box_now {
		// color:#4A8DF0;
		color: #409eff;
		font-weight: bold;
		font-size: 18px;
	}

	.level_box_old {
		color: #ccc;
		font-weight: bold;
		font-size: 18px;
		margin-left: 40px;
	}

	.level_box_btnbox {
		flex: 1;
		display: inline;
		text-align: right;

		// background-color: blue;
		.level_box_btnbox_audit {
			width: 80px;
			// height: 40px;
		}

		.level_box_btnbox_export {
			width: 80px;
		}
	}

	.level_box_levelbox {
		flex: 1;
	}
}
</style>

<style lang="scss">
.talentBox {
	.assessBox {
		.app-container {
			color: #484848;
			padding: 0;

			// background-color: #f6f7fb;
			.el-form {
				.is-error {
					margin: 30px 0 !important;
				}

				.viewbox {
					.is-error:last-of-type {
						margin-bottom: 0 !important;
					}
				}

				.fwn {
					.el-form-item__label {
						font-weight: normal;
						// transform: translateY(-24px);
						transform: translateY(-10px);
						width: 100%;
						text-align: left;
					}
				}
			}

			.titlebox {
				text-align: center;
				line-height: 17px;
				background: #fff;
				font: bold 18px/25px 'Microsoft YaHei';
				padding: 14px 40px;

				> .el-button {
					float: left;
					color: #484848;
					transform: translateY(-8px);
					font-size: 16px;
				}

				text-align: center;
				line-height: 17px;
				font: bold 18px/25px 'Microsoft YaHei';
				margin: 20px;
				// .title {
				// }
			}

			.viewbox {
				.is-required:last-of-type {
					margin-bottom: 0 !important;
				}

				label {
					font-weight: normal;
				}

				padding: 0 0 0 60px;
				background: #fff;
				margin: 0 20px;

				.el-radio {
					margin-right: 20px;
				}

				> p {
					color: #4a8df0;
					font-weight: bold;
					font-size: 18px;
				}

				> div {
					// margin-bottom: 14px;
					font: bold 14px/25px 'Microsoft YaHei';

					span {
						font-weight: normal;
					}
				}

				> div:nth-child(8) {
					margin-bottom: 0;
				}

				.title {
					font: bold 18px/25px 'Microsoft YaHei';
					color: #484848;
				}

				.ptitle {
					font: bold 14px/25px 'Microsoft YaHei';
					margin-bottom: 10px;
				}

				.el-textarea {
					width: 80%;
					// margin-left: 10px;
					vertical-align: middle;

					.el-textarea__inner {
						background-color: #f9fbff;
					}
				}

				> .el-textarea {
					width: 80%;
					margin-left: 0;
				}

				.radioArr {
					margin-left: 30px;
					margin-bottom: 10px;

					.el-radio {
						margin-right: 40px;
					}
				}
			}

			.qualityevaluation {
				padding: 30px 0 10px 60px;
				background: #fff;
				margin: 0 20px 20px;

				.is-error {
					margin-top: 0 !important;
				}

				div {
					font-weight: bold;

					span {
						font-weight: normal;
						font-size: 14px;
					}
				}

				.title {
					font: bold 18px/25px 'Microsoft YaHei';
					margin-bottom: 10px;
				}

				.ptitle {
					font: bold 14px/25px 'Microsoft YaHei';
					margin: 10px 0;
				}

				.el-textarea {
					width: 80%;
					margin: 5px 0px;
					vertical-align: middle;

					.el-textarea__inner {
						background-color: #f9fbff;
					}
				}

				.big-textarea {
					display: block;
					width: 80%;
					margin-left: 0;
				}

				.radioArr {
					.el-radio {
						width: 100%;
						margin: 10px 0;
					}
				}

				.Talentstype {
					margin-top: 15px;

					.el-form-item__label {
						font-size: 14px;
					}

					.radioArr {
						width: 80%;
						margin-left: 10px;

						.el-radio {
							width: 10%;
							margin: 10px 5px;
						}
					}
				}

				.buttonBox {
					margin-top: 20px;
					padding: 0 25%;

					.el-button {
						width: 100px;
					}
				}
			}

			.Postproposal {
				margin-top: 15px;
				margin-bottom: 15px;

				.el-form-item__label {
					font-size: 14px;
				}

				.el-textarea {
					margin-left: 0;
					display: block;
					width: 80%;

					.el-textarea__inner {
						background-color: #f9fbff;
					}
				}

				.radioArr {
					width: 80%;
					margin-left: 10px;

					// display: flex;
					.el-radio {
						// flex-wrap: wrap;
						width: 12%;
						margin: 10px 5px;
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.assessBox .app-container .viewbox .viewbox_position {
	color: #3f3f3f;
	font-weight: normal;
}

.assessBox .app-container .qualityevaluation .buttonBox_cancel {
	color: #c5cdda;
	margin-right: 10%;
}

.assessBox .app-container .qualityevaluation .buttonBox_save {
	//   color: #c5cdda;
	margin-right: 10%;
}

.assessBox .app-container .qualityevaluation .Postproposal_input {
	margin-top: 20px;
}

// 覆盖elementUI的样式
.assessBox {
	// background-color: #fff;
	.app-container {
		.el-radio__label {
			color: #3f3f3f;
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}

.qualityevaluation {
	::v-deep.el-form-item__label {
		font-weight: bold;
	}
}
</style>
